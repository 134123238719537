html {
  font-size: 62.5%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}
